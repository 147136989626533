<template>
  <registration-codes-index :query="query" :fields="fields" :filters="filters" />
</template>

<script>
import Vue from 'vue';
import RegistrationCode from '~/models/RegistrationCode';
import RegistrationCodesIndex from '~/pages/shared/registration-codes/RegistrationCodesIndex';

export default Vue.extend({
  components: { RegistrationCodesIndex },

  middleware: 'employee',

  data: () => ({
    query: () => new RegistrationCode().include('occupation'),

    filters: [],

    fields: ['label', 'url', 'occupation', 'use_count', 'state', 'created_at', 'actions'],
  }),
});
</script>
